<template>
	<div class="main-contents code">
		<div class="tit">적립 항목 관리 / {{pointSaveCdNm}}
			<img src="/images/admin/list_shortcut.png" alt="목록가기" @click="movePage('back')"/>
		</div>
		<div class="search-box interval">
			<div class="sub_btn" @click="register(pointSaveCd)">
				+등록
			</div>
		</div>
	<!-- 적립항목관리 리스트 -->
	<div class="Board type3">
		<table class="Board_type3 admin">
			<colgroup>
				<col width="4%">
				<col width="16%">
				<col width="26%">
				<col width="26%">
				<col width="9%">
				<col width="9%">
				<col width="9%">
			</colgroup>
			<thead>
				<tr>
					<th>NO</th>
					<th>적립 포인트 항목명</th>
					<th>적립 포인트 항목설명</th>
					<th>적립 포인트 변경사유</th>
					<th>적립 포인트</th>
					<th>시작일</th>
					<th>종료일</th>
				</tr>
			</thead>
			<tbody v-if="poHistory.length > 0">
				<tr v-for="(row,rowIdx) in poHistory" :key="rowIdx">
					<td class="score">{{(pageInfo.pageUnit * (pageInfo.pageIndex-1)) + rowIdx + 1}}</td>
					<td class="score name" @click="modify(row.pointSaveCd, row.pointSaveCdApplyVer)">{{row.pointSaveItemNm}}&#9654;</td>
					<td class="score">{{row.pointSaveItemDesc}}</td>
					<td class="score">{{row.pointSaveModReasonCont}}</td>
					<td class="score">{{row.pointSaveAmt}}</td>
					<td class="score">{{row.applyStart}}</td>
					<td class="score">{{row.applyEnd}}</td>
				</tr>
			</tbody>
			<tbody v-else>
				<tr>
					<td colspan="7" class="none">등록된 적립 항목이 없습니다!</td>
				</tr>
    		</tbody>
		</table>
	</div>
	<!-- 페이징부분 -->
	<pagingComp :pageInfo="pageInfo" @page-click="goPage"/>
	</div>
</template>

<script>
import pagingComp from '@/components/PagingComp.vue';

export default {
	data(){
		return {
			pageIndex : 1,
			poHistory: [],
			pageInfo:{},
			pointSaveCd : this.$route.params.pointSaveCd || '',
			pointSaveCdNm : this.$route.params.pointSaveCdNm || '',
			pointSaveCdApplyVer : this.$route.params.pointSaveCdApplyVer || ''
		}
	},
	
	components:{
		pagingComp
	},

	mounted(){
		// console.log('aaaaaaaaaaaa',this.pointSaveCd);
		// console.log('이름이름이름',this.pointSaveCdNm);
		this.getPointItemHistory();
	},

	methods:{
		getPointItemHistory(){
			// pageUnit = '25';
			// pageSize = '10';
			// if(div) param.

			var param = {};
			param.pointSaveCd = this.pointSaveCd;
			param.pointSaveCdApplyVer = this.pointSaveCdApplyVer;

			this.$.httpPost('/api/main/adm/point/getPointItemHistory', {pointSaveCd : this.pointSaveCd, pointSaveCdApplyVer : this.pointSaveCdApplyVer})
				.then(res=>{
					this.poHistory = res.data.poHistory;
					this.pageInfo = res.data.pageInfo;
				}).catch(this.$.httpErrorCommon);
		},

		goPage(page){
			this.pageIndex = page;
			this.getPointItemHistory();
		},

		movePage(div){
			if(div=='back') this.$router.go(-1);
			else this.$router.push('MAN934M01');
		},

		modify(pointSaveCd, pointSaveCdApplyVer){
			var param={};
			param.pointSaveCd=pointSaveCd;
			param.pointSaveCdApplyVer= pointSaveCdApplyVer;

			// console.log('modify param', param);

			this.$.popup('/adm/man/MAN934P02',param)
				.then(res=>{
					if(res){
						this.getPointItemHistory();
					}
				})
		},
		register(pointSaveCd){
			var param={};
			param.pointSaveCd=pointSaveCd;
			this.$.popup('/adm/man/MAN934P02',param)
				.then(res=>{
					if(res){
						this.getPointItemHistory();
					}
				})
		}
	}

}
</script>

<style>

</style>